import React, { useEffect } from "react";
import "./Update.css";
import { useState } from "react";
import "./Show.css";
import Axios from "axios";
import Left from "./Left";
import { useParams } from "react-router-dom";
import { BASE_URL } from "./helper";

function Update() {
  const [name, setName] = useState();
  const [fname, setfName] = useState("");
  const [mname, setmName] = useState("");
  const [class1, setclass] = useState("");
  const [contact1, setcontact1] = useState("");
  const [contact2, setcontact2] = useState("");
  const [gender, setgender] = useState("");
  const [adhaar, setadhaar] = useState("");
  const [admissionno, setadmissionno] = useState("");
  const [admissiondate, setadmissiondate] = useState("");
  const [village, setvillage] = useState("");
  const [transport, settransport] = useState("");
  const [caste, setcaste] = useState("");
  const [dob, setdob] = useState("");
  const [siblings, setsiblings] = useState("");
  const [siblingsd, setsiblingsd] = useState("");
  const [Concession, setconcession] = useState("");
  const [id, setid] = useState("");
  const [adm, setadm] = useState("");
  const [studentslist, setstudentslist] = useState([]);
  const params = useParams();
  console.log("params", params.id);
  useEffect(() => {
    const getstudent = () => {
      Axios.get(`${BASE_URL}/studentid/${params.id}`).then((response) => {
        setstudentslist(response.data);
        setName(response.data[0].Student_Name);
        setfName(response.data[0].Father_Name);
        setmName(response.data[0].Mother_Name);
        setclass(response.data[0].Class);
        setcontact1(response.data[0].Contact_No_1);
        setcontact2(response.data[0].Contact_No_2);
        setgender(response.data[0].Gender);
        setadhaar(response.data[0].AADHAAR_No);
        setadmissionno(response.data[0].Admission_No);
        setdob(response.data[0].DOB);
        setadmissiondate(response.data[0].Admission_Date);
        setvillage(response.data[0].Village);
        settransport(response.data[0].Transport);
        setcaste(response.data[0].Caste);
        setsiblings(response.data[0].Siblings);
        setsiblingsd(response.data[0].Siblings_Details);
        setconcession(response.data[0].Concession);
        setid(response.data[0].id);
      });
    };
    getstudent();
  }, []);

  const updatestudent = (id) => {
    Axios.put(`${BASE_URL}/update`, {
      name: name,
      fname: fname,
      mname: mname,
      gender: gender,
      contact1: contact1,
      contact2: contact2,
      class1: class1,
      adhaar: adhaar,
      admissionno: admissionno,
      admissiondate: admissiondate,
      village: village,
      transport: transport,
      caste: caste,
      siblings: siblings,
      siblingsd: siblingsd,
      Concession: Concession,
      dob: dob,
    }).then((response) => {
      setstudentslist(
        studentslist.map((val) => {
          return val.id === id
            ? {
                id: val.id,
                name: val.Student_Name,
                fname: val.Father_Name,
                mname: val.Mother_Name,
                gender: val.Gender,
                contact1: val.Contact_No_1,
                contact2: val.Contact_No_2,
                class1: val.Class,
                adhaar: val.AADHAAR_No,
                admissionno: val.Admission_No,
                admissiondate: val.Admission_Date,
                village: val.Village,
                transport: val.Transport,
                caste: val.Caste,
                siblings: val.Siblings,
                siblingsd: val.Siblings_Details,
                Concession: val.Concession,
                dob: val.DOB,
              }
            : val;
        })
      );
    });
  };
  const refresh = () => window.location.reload(true);
  const date = (date1) => {
    date1 = date1.substr(0, 10);
    return date1;
  };
  return (
    <div className="home-u">
      <Left />
      <div className="right-u">
        {/* <div className="search-u">
          <input
            type="text"
            onChange={(event) => {
              setadm(event.target.value);
            }}
            placeholder="Enter Admission number"
          />
          <button onClick={getstudent}>Click</button>
          <button onClick={getallstudent}>all students</button>
        </div> */}
        <div className="display-u">
          <table
            className="table-u"
            width="700"
            border="0"
            align="center"
            cellpadding="0"
            cellspacing="5"
          >
            {/* {studentslist.map((val, key) => {
              return (
                <tr key={key}> */}
            <tr>
              <td>
                <strong>Name:</strong>
              </td>

              <td className="output-u">
                <input
                  name="name"
                  type="text"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>DOB:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={date(dob)}
                  onChange={(event) => {
                    setdob(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Gender:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={gender}
                  onChange={(event) => {
                    setgender(event.target.value);
                  }}
                />
              </td>
            </tr>
            {/* <tr>
                        {" "}
                        
                      </tr> */}
            <tr>
              <td>
                <strong>Class:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={class1}
                  onChange={(event) => {
                    setclass(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Admission number:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={admissionno}
                  onChange={(event) => {
                    setadmissionno(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Admission Date:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={date(admissionno)}
                  onChange={(event) => {
                    setadmissiondate(event.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <strong>Adhaar number:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={adhaar}
                  onChange={(event) => {
                    setadhaar(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Fathers name:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={fname}
                  onChange={(event) => {
                    setfName(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Mothers name:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={mname}
                  onChange={(event) => {
                    setmName(event.target.value);
                  }}
                />
              </td>

              <td>&nbsp;</td>
            </tr>

            <tr>
              <td>
                <strong>Contact 1:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={contact1}
                  onChange={(event) => {
                    setcontact1(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Contact 2:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={contact2}
                  onChange={(event) => {
                    setcontact2(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Caste:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={caste}
                  onChange={(event) => {
                    setcaste(event.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <strong>Village:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={village}
                  onChange={(event) => {
                    setvillage(event.target.value);
                  }}
                />
              </td>{" "}
              <td>
                <strong>Transport:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={transport}
                  onChange={(event) => {
                    settransport(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Concession:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={Concession}
                  onChange={(event) => {
                    setconcession(event.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <strong>Siblings:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={siblings}
                  onChange={(event) => {
                    setsiblings(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Siblings details:</strong>
              </td>
              <td className="output-u">
                <input
                  Value={siblingsd}
                  onChange={(event) => {
                    setsiblingsd(event.target.value);
                  }}
                />
              </td>
              <td>
                <button
                  className="update"
                  onClick={() => {
                    updatestudent(id);
                    refresh();
                  }}
                >
                  Update
                </button>
              </td>
            </tr>
            {/* </tr>
              );
            })} */}
          </table>
        </div>
      </div>
    </div>
  );
}
export default Update;
