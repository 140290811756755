import React from "react";
import "./Left.css";
import { Link } from "react-router-dom";

function Left() {
  return (
    <div className="left-h">
      <div class="dropdown">
        <button class="dropbtn">Student Management</button>
        <div class="dropdown-content">
          <Link className="class1" to={"/Add"}>
            <button>Add a student</button>
          </Link>
          <Link className="class1" to={"/Show"}>
            <button>Search</button>
          </Link>
          <Link className="class1" to={"/Filter"}>
            <button>Filter</button>
          </Link>
          <Link className="class1" to={"/UD"}>
            <button>Update/Delete</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Left;
