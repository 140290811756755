import React from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
function Header() {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("user");
    navigate("/Login");
  };
  return (
    <div className="header">
      <div className="navbar">
        <button onClick={logout}>logout</button>
      </div>
    </div>
  );
}

export default Header;
