import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Login from "./Login";
const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user === "true") {
    return true;
  } else {
    return false;
  }
};
function ProtectedRoute() {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/Login" />;
}

export default ProtectedRoute;
