import "./App.css";
import Header from "./Header";
import Homepage from "./Homepage";
import Show from "./Shows";
import Filter from "./Filter";
import Update from "./Update";
import Delete from "./Delete";
import Left from "./Left";
import UD from "./UD";
import Login from "./Login";
import ProtectedRoute from "./ProtectedRoute";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Left />
                </>
              }
            />
            <Route
              path="/Add"
              element={
                <>
                  <Header />
                  <Homepage />
                </>
              }
            />
            <Route
              path="/Show"
              element={
                <>
                  <Header />
                  <Show />
                </>
              }
            />
            <Route
              path="/Filter"
              element={
                <>
                  <Header />
                  <Filter />
                </>
              }
            />
            <Route
              path="/Update/:id"
              element={
                <>
                  <Header />
                  <Update />
                </>
              }
            />
            <Route
              path="/Delete/:id"
              element={
                <>
                  <Header />
                  <Delete />
                </>
              }
            />
            <Route
              path="/UD"
              element={
                <>
                  <Header />
                  <UD />
                </>
              }
            />
          </Route>
          <Route
            path="/Login"
            element={
              <>
                <Login />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
    // <Header/>
  );
}

export default App;
