import React, { useMemo } from "react";
import { useState } from "react";
import "./UD.css";
import Axios from "axios";
import { MaterialReactTable, startsWith } from "material-react-table";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BASE_URL } from "./helper";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import Left from "./Left";
import Update from "./Update";

function UD() {
  const [filter, setfilter] = useState(false);
  const [adm, setadm] = useState("");
  const [class1, setclass] = useState("");
  const [studentslist, setstudentslist] = useState([]);
  const getstudent = () => {
    Axios.get(`${BASE_URL}/class`, {
      params: { class1: class1 },
    }).then((response) => {
      console.log(response.data);
      setstudentslist(response.data);
    });
  };

  const classlist = [
    "Nursery",
    "LKG",
    "UKG",
    "UKG B",
    "I A",
    "I B",
    "II",
    "II A",
    "III",
    "IV A",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
  ];
  const genderlist = ["M", "F"];
  return (
    <div className="home-s">
      <Left />
      <div className="right-ud">
        <div className="search-s">
          <input
            list="classes"
            id="class2"
            name="class2"
            onChange={(event) => {
              setclass(event.target.value);
            }}
          />
          <datalist id="classes">
            {classlist.map((element) => {
              return <option value={element}></option>;
            })}
          </datalist>

          <button onClick={getstudent}>Click</button>
        </div>
        <div className="display-s">
          <table>
            <tr className="tr1">
              <td> </td>
              <td> </td>
              <td>admissionno</td>
              <td>Name</td>
              <td>Class</td>
              <td>Father Name</td>
            </tr>

            {studentslist.map((val, key) => {
              return (
                <tr className="tr2" key={key}>
                  <td className="edit">
                    <Link className="class2" to={"/Update/" + val.id}>
                      <Tooltip arrow placement="left" title="Edit">
                        <IconButton
                          onClick={() => {
                            console.log("val.id", val.id);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </td>
                  <td className="edit">
                    <Link className="class2" to={"/Delete/" + val.id}>
                      <Tooltip arrow placement="left" title="Delete">
                        <IconButton>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </td>
                  <td>{val.Admission_No}</td>
                  <td>{val.Student_Name}</td>
                  <td>{val.Class}</td>
                  <td>{val.Father_Name}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
}

export default UD;
