import React from "react";
import { useState } from "react";
import "./Show.css";
import Axios from "axios";
import Left from "./Left";
import { BASE_URL } from "./helper";

function Show() {
  const [adm, setadm] = useState("");
  const [studentslist, setstudentslist] = useState([]);
  const getstudent = () => {
    Axios.get(`${BASE_URL}/student`, {
      params: { adm: adm },
    }).then((response) => {
      console.log(response.data);
      setstudentslist(response.data);
    });
  };
  const getallstudent = () => {
    Axios.get(`${BASE_URL}/allstudent`).then((response) => {
      console.log(response.data);
      setstudentslist(response.data);
    });
  };
  const date = (date1) => {
    date1 = date1.substr(0, 10);
    return date1;
  };

  return (
    <div className="home-s">
      <Left />
      <div className="right-s">
        <div className="search-s">
          <input
            type="text"
            onChange={(event) => {
              setadm(event.target.value);
            }}
            placeholder="Enter Admission number"
          />
          <button onClick={getstudent}>Click</button>
          <button onClick={getallstudent}>all students</button>
        </div>
        <div className="display-s">
          <table
            className="table-s"
            width="700"
            border="0"
            align="center"
            cellpadding="0"
            cellspacing="5"
          >
            {studentslist.map((val, key) => {
              return (
                <tr key={key}>
                  <tr>
                    <td>
                      <strong>Name:</strong>
                    </td>
                    <td className="output-s">
                      <output name="name">{val.Student_Name}</output>
                    </td>
                    <td>
                      <strong>DOB:</strong>
                    </td>
                    <td className="output-s">
                      <output>{date(val.DOB)}</output>
                    </td>
                    <td>
                      <strong>Gender:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Gender}</output>
                    </td>
                  </tr>
                  {/* <tr>
                    {" "}
                    
                  </tr> */}
                  <tr>
                    <td>
                      <strong>Class:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Class}</output>
                    </td>
                    <td>
                      <strong>Admission number:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Admission_No}</output>
                    </td>
                    <td>
                      <strong>Admission Date:</strong>
                    </td>
                    <td className="output-s">
                      <output>{date(val.Admission_Date)}</output>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Adhaar number:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.AADHAAR_No}</output>
                    </td>
                    <td>
                      <strong>Fathers name:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Father_Name}</output>
                    </td>
                    <td>
                      <strong>Mothers name:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Mother_Name}</output>
                    </td>

                    <td>&nbsp;</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Contact 1:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Contact_No_1}</output>
                    </td>
                    <td>
                      <strong>Contact 2:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Contact_No_2}</output>
                    </td>
                    <td>
                      <strong>Caste:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Caste}</output>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Village:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Village}</output>
                    </td>
                    <td>
                      <strong>Transport:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Transport}</output>
                    </td>
                    <td>
                      <strong>Concession:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Concession}</output>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Siblings:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Siblings}</output>
                    </td>
                    <td>
                      <strong>Siblings details:</strong>
                    </td>
                    <td className="output-s">
                      <output>{val.Siblings_Details}</output>
                    </td>
                  </tr>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </div>
  );
}

export default Show;
