import React, { useMemo } from "react";
import { useState } from "react";
import "./Filter.css";
import Axios from "axios";
import { MaterialReactTable, startsWith } from "material-react-table";
import { ThemeProvider, createTheme } from "@mui/material";
import { Checkbox } from "@material-ui/core";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExportToCsv } from "export-to-csv";
import * as XLSX from "xlsx";
import { rankItem, rankings, RankingInfo } from "@tanstack/match-sorter-utils";
import { equalsString } from "@tanstack/react-table";
import Left from "./Left";
import { BASE_URL } from "./helper";

function Filter() {
  const [filter, setfilter] = useState(false);
  const [adm, setadm] = useState("");
  const [studentslist, setstudentslist] = useState([]);
  const getstudent = () => {
    Axios.get(`${BASE_URL}student`, {
      params: { adm: adm },
    }).then((response) => {
      console.log(response.data);
      setstudentslist(response.data);
    });
  };
  const getallstudent = () => {
    Axios.get(`${BASE_URL}/allstudent`).then((response) => {
      console.log(response.data);
      setstudentslist(response.data);
    });
  };

  const villagelist = [
    "Gudrana",
    "Mitrol",
    "Tumasra",
    "Deeghot",
    "Kondal",
    "Aurangabad",
    "Palwal",
    "Rundhi",
    "Marroli",
    "Palri",
    "Sri Nagar",
    "Prithla",
    "Khatela",
    "Bamnikheda",
    "Bhmari",
    "Mansa Green",
    "Gopalgarh",
    "Gudrana",
    "Maroli",
  ];
  const classlist = [
    "Nursery",
    "LKG",
    "UKG",
    "UKG B",
    "I A",
    "I B",
    "II",
    "II A",
    "III",
    "IV A",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
  ];
  const genderlist = ["M", "F"];
  const transportlist = [
    "Van- Rahul 1st Round",
    "E1 - Pratap Sr. Round",
    "E5 - Bhupender Jr. Round",
    "E6 - Devi Singh Jr. Round",
    "E2 - Rahul Jr. Round",
    "E4 - Jagdish Jr. Round",
    "B2 Old Bus - Sr. Round",
    "B1 New Bus - Jr. Round",
    "B1 New Bus - Sr. Round",
    "E5 - Bhupender Sr. Round",
    "E2 - Rahul Sr. Round",
    "E1 - Pratap Jr. Round",
    "B3 Force Bus - Sr. Round",
    "Self",
    "E3 - Kishan 3rd Round",
    "E4 - Jagdish Sr. Round",
    "Ecco Faridabad",
    "Van - Pratap Round 1",
    "E3 - Kishan Sr. Round",
    "E3 - Kishan Jr. Round",
    "Van- Rahul 2nd Round",
    "E6 - Devi Singh Sr. Round",
    "E5 - Bhupender Sr. Roun",
    "Bus Round 1",
    "Bus 0183",
    "Van - Pratap Round 2",
  ];

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "Student_Name",
        // filterVariant: "text",
        enableColumnFilter: false,
      },
      {
        header: "Admission_No:",
        accessorKey: "Admission_No",
        filterVariant: "text",
      },
      {
        header: "Class",
        accessorKey: "Class",
        // filterFn: "equalsString",
        filterVariant: "multi-select",
        filterSelectOptions: classlist,
        filterFn: "customFilterFn",
      },
      {
        header: "Gender",
        accessorKey: "Gender",
        // filterFn: "equals",
        filterSelectOptions: [
          { text: "Male", value: "M" },
          { text: "Female", value: "F" },
        ],
        filterVariant: "multi-select",
      },
      {
        header: "Father_Name",
        accessorKey: "Father_Name",
        enableColumnFilter: false,
      },
      {
        header: "Mother_Name",
        accessorKey: "Mother_Name",
        enableColumnFilter: false,
      },

      {
        header: "village",
        accessorKey: "Village",
        filterVariant: "multi-select",
        filterSelectOptions: villagelist,
      },
      {
        header: "Transport",
        accessorKey: "Transport",
        filterVariant: "multi-select",
        filterSelectOptions: transportlist,
      },
    ],
    []
  );

  const downloadpdf = (rows) => {
    const doc = new jsPDF();
    doc.text("student details", 20, 10);
    doc.autoTable({
      columns: columns.map((colo) => ({ ...colo, dataKey: colo.accessorKey })),
      body: rows.map((row) => row.original),
    });
    doc.save("table.pdf");
  };

  const downloadexcel = (rows) => {
    const worksheet = XLSX.utils.json_to_sheet(rows.map((row) => row.original));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "studets");
    let buff = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    XLSX.writeFile(workbook, "StudentsData.xlsx");
  };
  return (
    <div className="home-f">
      <Left />
      <div className="right-f">
        <div className="search-f">
          <div className="search-fdiv">
            <input
              type="text"
              onChange={(event) => {
                setadm(event.target.value);
              }}
              placeholder="Enter Admission number"
            />

            <button onClick={getstudent}>Click</button>
            <button onClick={getallstudent}>all students</button>
          </div>
        </div>
        <div className="display-f">
          <div className="result">
            <MaterialReactTable
              columns={columns}
              data={studentslist}
              initialState={{ showColumnFilters: false }}
              filterFns={{
                customFilterFn: function (row, columnId, filterValue) {
                  const search = filterValue;
                  console.log(search);
                  const value = row.getValue(columnId);
                  console.log("value", value);
                  if (typeof value === "number") value = String(value);
                  return value === null || value === void 0
                    ? void 0
                    : value.toString().startsWith(search);
                },
              }}
              positionToolbarAlertBanner="bottom"
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    color="primary"
                    disabled={
                      table.getPrePaginationRowModel().rows.length === 0
                    }
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                      downloadpdf(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    pdf
                  </Button>
                  <Button
                    color="primary"
                    disabled={
                      table.getPrePaginationRowModel().rows.length === 0
                    }
                    //export all rows, including from the next page, (still respects filtering and sorting)
                    onClick={() =>
                      downloadexcel(table.getPrePaginationRowModel().rows)
                    }
                    startIcon={<FileDownloadIcon />}
                    variant="contained"
                  >
                    Excel
                  </Button>
                </Box>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
