import React, { useEffect } from "react";
import "./Delete.css";
import { useState } from "react";
import "./Show.css";
import Axios from "axios";
import Left from "./Left";
import { useParams } from "react-router-dom";
import { BASE_URL } from "./helper";
function Delete() {
  const [name, setName] = useState();
  const [fname, setfName] = useState("");
  const [mname, setmName] = useState("");
  const [class1, setclass] = useState("");
  const [contact1, setcontact1] = useState("");
  const [contact2, setcontact2] = useState("");
  const [gender, setgender] = useState("");
  const [adhaar, setadhaar] = useState("");
  const [admissionno, setadmissionno] = useState("");
  const [admissiondate, setadmissiondate] = useState("");
  const [village, setvillage] = useState("");
  const [transport, settransport] = useState("");
  const [caste, setcaste] = useState("");
  const [dob, setdob] = useState("");
  const [siblings, setsiblings] = useState("");
  const [siblingsd, setsiblingsd] = useState("");
  const [Concession, setconcession] = useState("");
  const [adm, setadm] = useState("");
  const [id, setid] = useState("");
  const [studentslist, setstudentslist] = useState([]);
  const params = useParams();

  useEffect(() => {
    const getstudent = () => {
      Axios.get(`${BASE_URL}/studentid/${params.id}`).then((response) => {
        setstudentslist(response.data);
        setName(response.data[0].Student_Name);
        setfName(response.data[0].Father_Name);
        setmName(response.data[0].Mother_Name);
        setclass(response.data[0].Class);
        setcontact1(response.data[0].Contact_No_1);
        setcontact2(response.data[0].Contact_No_2);
        setgender(response.data[0].Gender);
        setadhaar(response.data[0].AADHAAR_No);
        setadmissionno(response.data[0].Admission_No);
        setdob(response.data[0].DOB);
        setadmissiondate(response.data[0].Admission_Date);
        setvillage(response.data[0].Village);
        settransport(response.data[0].Transport);
        setcaste(response.data[0].Caste);
        setsiblings(response.data[0].Siblings);
        setsiblingsd(response.data[0].Siblings_Details);
        setconcession(response.data[0].Concession);
        setid(response.data[0].id);
      });
    };
    getstudent();
  }, []);

  const deletestudent = (id) => {
    Axios.delete(`${BASE_URL}/deletestudent/${id}`).then((response) => {
      setstudentslist(
        studentslist.Filter((val) => {
          return val.id !== id;
        })
      );
    });
  };
  const refresh = () => window.location.reload(true);
  const date = (date1) => {
    date1 = date1.substr(0, 10);
    return date1;
  };
  return (
    <div className="home-d">
      <Left />
      <div className="right-d">
        <div className="display-d">
          <table
            className="table-d"
            width="700"
            border="0"
            align="center"
            cellpadding="0"
            cellspacing="5"
          >
            {/* {studentslist.map((val, key) => {
              return (
                <tr key={key}> */}
            <tr>
              <td>
                <strong>Name:</strong>
              </td>

              <td className="output-d">
                <input
                  name="name"
                  type="text"
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>DOB:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={date(dob)}
                  onChange={(event) => {
                    setdob(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Gender:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={gender}
                  onChange={(event) => {
                    setgender(event.target.value);
                  }}
                />
              </td>
            </tr>
            {/* <tr>
                        {" "}
                        
                      </tr> */}
            <tr>
              <td>
                <strong>Class:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={class1}
                  onChange={(event) => {
                    setclass(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Admission number:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={admissionno}
                  onChange={(event) => {
                    setadmissionno(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Admission Date:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={date(admissiondate)}
                  onChange={(event) => {
                    setadmissiondate(event.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <strong>Adhaar number:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={adhaar}
                  onChange={(event) => {
                    setadhaar(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Fathers name:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={fname}
                  onChange={(event) => {
                    setfName(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Mothers name:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={mname}
                  onChange={(event) => {
                    setmName(event.target.value);
                  }}
                />
              </td>

              <td>&nbsp;</td>
            </tr>

            <tr>
              <td>
                <strong>Contact 1:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={contact1}
                  onChange={(event) => {
                    setcontact1(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Contact 2:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={contact2}
                  onChange={(event) => {
                    setcontact2(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Caste:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={caste}
                  onChange={(event) => {
                    setcaste(event.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <strong>Village:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={village}
                  onChange={(event) => {
                    setvillage(event.target.value);
                  }}
                />
              </td>{" "}
              <td>
                <strong>Transport:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={transport}
                  onChange={(event) => {
                    settransport(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Concession:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={Concession}
                  onChange={(event) => {
                    setconcession(event.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>
                <strong>Siblings:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={siblings}
                  onChange={(event) => {
                    setsiblings(event.target.value);
                  }}
                />
              </td>
              <td>
                <strong>Siblings details:</strong>
              </td>
              <td className="output-d">
                <input
                  Value={siblingsd}
                  onChange={(event) => {
                    setsiblingsd(event.target.value);
                  }}
                />
              </td>
              <td>
                <button
                  className="update"
                  onClick={() => {
                    deletestudent(id);
                    refresh();
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
            {/* </tr>
              );
            })} */}
          </table>
        </div>
      </div>
    </div>
  );
}
export default Delete;
