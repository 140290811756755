import React, { useState } from "react";
import Axios from "axios";
import { BASE_URL } from "./helper";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { FitScreen } from "@mui/icons-material";
function Login() {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [loginstatus, setloginstatus] = useState("");
  //   const [authenticated, setauthenticated] = useState(
  //     localStorage.getItem(localStorage.getItem("authenticated") || false)
  //   );
  const navigate = useNavigate();
  const login1 = () => {
    Axios.post(`${BASE_URL}/Login`, {
      username: username,
      password: password,
    }).then((response) => {
      console.log("user", localStorage.getItem("user"));
      if (response.data.message) {
        setloginstatus(response.data.message);
        localStorage.setItem("user", "false");
        console.log("user", localStorage.getItem("user"));
      } else {
        setloginstatus(response.data[0].username);
        localStorage.setItem("user", "true");
        console.log("user", localStorage.getItem("user"));
        navigate("/");
      }
    });
  };
  return (
    // <div>
    //   <p>Welcome Back</p>
    //   <input
    //     type="text"
    //     name="Username"
    //     value={username}
    //     onChange={(e) => setusername(e.target.value)}
    //   />
    //   <input
    //     type="password"
    //     name="Password"
    //     onChange={(e) => setpassword(e.target.value)}
    //   />
    //   <button onClick={login1}>Click</button>
    //   <h1>{loginstatus}</h1>
    // </div>
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100 p-t-85 p-b-20">
          <div class="login100-form validate-form">
            <span class="login100-form-title p-b-70">Welcome</span>
            <span class="login100-form-avatar">
              <img
                style={{ objectFit: "cover" }}
                src="./images/ramanujanlogo.png"
              />
            </span>
            <div
              class="wrap-input100 validate-input m-t-85 m-b-35"
              data-validate="Enter username"
            >
              <input
                class="input100"
                type="text"
                name="username"
                onChange={(e) => setusername(e.target.value)}
              />
              <span class="focus-input100" data-placeholder="Username"></span>
            </div>
            <div
              class="wrap-input100 validate-input m-b-50"
              data-validate="Enter password"
            >
              <input
                class="input100"
                type="password"
                name="pass"
                onChange={(e) => setpassword(e.target.value)}
              />
              <span class="focus-input100" data-placeholder="Password"></span>
            </div>
            <div class="container-login100-form-btn">
              <button class="login100-form-btn" onClick={login1}>
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
