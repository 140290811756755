import React from "react";
import { useState } from "react";
import "./Homepage.css";
import Axios from "axios";
import { Link } from "react-router-dom";
import Show from "./Shows";
import Left from "./Left";
import { BASE_URL } from "./helper";

function Homepage() {
  const [name, setName] = useState("");
  const [fname, setfName] = useState("");
  const [mname, setmName] = useState("");
  const [class1, setclass] = useState("");
  const [contact1, setcontact1] = useState("");
  const [contact2, setcontact2] = useState("");
  const [gender, setgender] = useState("");
  const [adhaar, setadhaar] = useState("");
  const [admissionno, setadmissionno] = useState("");
  const [admissiondate, setadmissiondate] = useState("");
  const [village, setvillage] = useState("");
  const [transport, settransport] = useState("");
  const [caste, setcaste] = useState("");
  const [dob, setdob] = useState("");
  const [siblings, setsiblings] = useState("");
  const [siblingsd, setsiblingsd] = useState("");
  const [Concession, setconcession] = useState("");
  const [studentslist, setstudentslist] = useState([]);
  const getstudent = () => {
    Axios.get(
      `${BASE_URL}/student`.then((response) => {
        setstudentslist(response.data);
      })
    );
  };
  const Addstudent = () => {
    Axios.post(`${BASE_URL}/create`, {
      name: name,
      fname: fname,
      mname: mname,
      gender: gender,
      contact1: contact1,
      contact2: contact2,
      class1: class1,
      adhaar: adhaar,
      admissionno: admissionno,
      admissiondate: admissiondate,
      village: village,
      transport: transport,
      caste: caste,
      siblings: siblings,
      siblingsd: siblingsd,
      Concession: Concession,
      dob: dob,
    }).then(() => {
      console.log("success");
    });
  };
  const refresh = () => window.location.reload(true);
  const classlist = [
    "Nursery",
    "LKG",
    "UKG",
    "UKG B",
    "I A",
    "I B",
    "II",
    "II A",
    "III",
    "IV A",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
  ];
  const villagelist = [
    "Gudrana",
    "Mitrol",
    "Tumasra",
    "Deeghot",
    "Kondal",
    "Aurangabad",
    "Palwal",
    "Rundhi",
    "Marroli",
    "Palri",
    "Sri Nagar",
    "Prithla",
    "Khatela",
    "Bamnikheda",
    "Bhmari",
    "Mansa Green",
    "Gopalgarh",
    "Gudrana",
    "Maroli",
  ];
  const transportlist = [
    "Van- Rahul 1st Round",
    "E1 - Pratap Sr. Round",
    "E5 - Bhupender Jr. Round",
    "E6 - Devi Singh Jr. Round",
    "E2 - Rahul Jr. Round",
    "E4 - Jagdish Jr. Round",
    "B2 Old Bus - Sr. Round",
    "B1 New Bus - Jr. Round",
    "B1 New Bus - Sr. Round",
    "E5 - Bhupender Sr. Round",
    "E2 - Rahul Sr. Round",
    "E1 - Pratap Jr. Round",
    "B3 Force Bus - Sr. Round",
    "Self",
    "E3 - Kishan 3rd Round",
    "E4 - Jagdish Sr. Round",
    "Ecco Faridabad",
    "Van - Pratap Round 1",
    "E3 - Kishan Sr. Round",
    "E3 - Kishan Jr. Round",
    "Van- Rahul 2nd Round",
    "E6 - Devi Singh Sr. Round",
    "E5 - Bhupender Sr. Roun",
    "Bus Round 1",
    "Bus 0183",
    "Van - Pratap Round 2",
  ];

  return (
    <div className="home-h">
      <Left />
      <div className="right-h">
        <table
          className="table-h"
          width="700"
          border="0"
          align="center"
          cellpadding="0"
          cellspacing="5"
        >
          <tr>
            <td>
              <strong>Name(*):</strong>
            </td>
            <td>
              <input
                name="name"
                type="text"
                size="35"
                required
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
            </td>
            <td>
              <strong>DOB:</strong>
            </td>
            <td>
              <input
                type="date"
                onChange={(event) => {
                  setdob(event.target.value);
                }}
                required
              />
            </td>
            {/* </tr>
          <tr> */}
            <td>
              <strong>Gender(*):</strong>
            </td>

            <td>
              <input
                type="radio"
                name="gender"
                value="Male"
                onChange={(event) => {
                  setgender(event.target.value);
                  console.log(event.target.value);
                }}
              ></input>
            </td>
            <td>
              <strong>Male</strong>
            </td>

            <td>
              <input
                type="radio"
                name="gender"
                value="Female"
                onChange={(event) => {
                  setgender(event.target.value);
                }}
              ></input>
            </td>
            <td>
              <strong>Female</strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Class:</strong>
            </td>
            <td>
              <input
                list="classes"
                id="class2"
                name="class2"
                onChange={(event) => {
                  setclass(event.target.value);
                }}
                required
              />
              <datalist id="classes">
                {classlist.map((element) => {
                  return <option value={element}></option>;
                })}
              </datalist>
            </td>
            <td>
              <strong>Admission number:</strong>
            </td>
            <td>
              <input
                type="text"
                size="35"
                onChange={(event) => {
                  setadmissionno(event.target.value);
                }}
                required
              />
            </td>
            <td>
              <strong>Admission Date:</strong>
            </td>
            <td>
              <input
                type="date"
                size="35"
                onChange={(event) => {
                  setadmissiondate(event.target.value);
                }}
                required
              />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Adhaar number(*):</strong>
            </td>
            <td>
              <input
                type="text"
                size="35"
                onChange={(event) => {
                  setadhaar(event.target.value);
                }}
                required
              />
            </td>
            <td>
              <strong>Fathers name:</strong>
            </td>
            <td>
              <input
                type="text"
                size="35"
                onChange={(event) => {
                  setfName(event.target.value);
                }}
                required
              />
            </td>
            <td>
              <strong>Mothers name:</strong>
            </td>
            <td>
              <input
                type="text"
                size="35"
                onChange={(event) => {
                  setmName(event.target.value);
                }}
                required
              />
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <strong>Contact 1(*):</strong>
            </td>
            <td>
              <input
                type="text"
                onChange={(event) => {
                  setcontact1(event.target.value);
                }}
                size="35"
              />
            </td>
            <td>
              <strong>Contact 2:</strong>
            </td>
            <td>
              <input
                type="text"
                onChange={(event) => {
                  setcontact2(event.target.value);
                }}
                size="35"
              />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Village(*):</strong>
            </td>
            <td>
              <input
                list="villages"
                id="class2"
                name="class2"
                onChange={(event) => {
                  setvillage(event.target.value);
                }}
                size="35"
              />
              <datalist id="villages">
                {villagelist.map((element) => {
                  return <option value={element}></option>;
                })}
              </datalist>
            </td>
            <td>
              <strong>Transport:</strong>
            </td>
            <td>
              <input
                list="transport"
                id="class2"
                name="class2"
                onChange={(event) => {
                  settransport(event.target.value);
                }}
                size="35"
              />
              <datalist id="transport">
                {transportlist.map((element) => {
                  return <option value={element}></option>;
                })}
              </datalist>
            </td>
            <td>
              <strong>Caste(*):</strong>
            </td>
            <td>
              <input
                type="text"
                onChange={(event) => {
                  setcaste(event.target.value);
                }}
                size="35"
              />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Siblings(*):</strong>
            </td>

            <td>
              <input
                type="radio"
                name="siblings"
                value="yes"
                onChange={(event) => {
                  setsiblings(event.target.value);
                }}
              ></input>
            </td>
            <td>
              <strong>Yes</strong>
            </td>

            <td>
              <input
                type="radio"
                name="siblings"
                value="no"
                onChange={(event) => {
                  setsiblings(event.target.value);
                }}
              ></input>
            </td>
            <td>
              <strong>NO</strong>
            </td>
            <td>&nbsp;&nbsp;&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>
              <strong>Siblings details:</strong>
            </td>
            <td>
              <input
                type="text"
                onChange={(event) => {
                  setsiblingsd(event.target.value);
                }}
                size="35"
              />
            </td>
            <td>
              <strong>Concession:</strong>
            </td>
            <td>
              <input
                type="text"
                onChange={(event) => {
                  setconcession(event.target.value);
                }}
                size="35"
              />
            </td>
          </tr>
          <tr>
            <td>
              <button
                className="submit"
                type="submit"
                value="Submit"
                onClick={() => {
                  if (
                    name === "" ||
                    gender === "" ||
                    contact1 === "" ||
                    village === "" ||
                    adhaar === "" ||
                    siblings === ""
                  )
                    alert("* is empty");
                  else {
                    Addstudent();
                    alert("your details have been submitted!");
                    refresh();
                  }
                }}
                onsubmit="alert('your details have been submitted!')"
              >
                submit
              </button>
            </td>
            <td>
              <button className="reset" type="reset" onClick={refresh}>
                reset
              </button>
            </td>

            <td>&nbsp;</td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default Homepage;
